import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { hourlyRate, monthlySeo, experienceYears } from "../../components/Helpers";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import Img from "../../components/Img.js";
import H from "../../components/Headline";
import ProfileCard from "../../components/ProfileCard";
import { useAlternateLangs } from "../../components/Hreflangs";
import ContactForm from "../../components/ContactForm";
import ImgScreenshot from "../../components/ImgScreenshot.js";


const breadCrumbLevels = {
    Etusivu: "/",
    "Tietoja minusta": "/fi/tietoa",
    "SEO Freelancer": "	/fi/seo-freelancerina"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
    // replace with pages x-default
    "/en/seo-freelancer"
);


const SeoFreelancer = props => {

    return (
        <Layout location={props.location} alternateLangs={alternateLangs}>
            <React.Fragment>
                <SEO
                    title="SEO Freelancer vuokrattavana: M. Kupperschmidtin profiili"
                    description="Olen freelance SEO-konsultti, jolla on monen vuoden kokemus toimistotyöstä, ja voit palkata minut nostamaan verkkosivustosi Googlen hakutulosten kärkeen. Ota yhteyttä suoraan."
                    lang="fi"
                    image="matthias-kupperschmidt-presenting-at-founders-house-oct-2019"
                    alternateLangs={alternateLangs}
                    canonical="	/fi/seo-freelancerina"
                />
                <MainContent article>
                    <Img
                        src="seo-freelancer/tag-management-consultant-hero-cropped.jpg"
                        alt="Matthias Kupperschmidt puhumassa avainsanojen vaikeudesta SEO-konsulttien tapaamisessa Kööpenhaminassa, Tanska, lokakuussa 2019"
                    />
                    <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
                    <H as="h1">Palkkaa freelance SEO-konsultti</H>
                    <ProfileCard
                        tags={["SEO Content", "Backlinks", "Technical SEO", "Onpage SEO", "HTML/CSS/JavaScript", "React", "Python", "EN, DE, DA"]}
                        profession="SEO-konsultti"
                        rate={`alkaen ${monthlySeo}€ kuukaudessa`}
                        location="Berliini, Saksa / etätyö"
                        cta="Ota yhteyttä"
                    />
<p>Olen SEO freelancer ja voit palkata minut nostamaan verkkosivustosi Googlen hakutulosten kärkeen.</p>
<p>Tarjoan saman palvelun kuin SEO-toimisto, mutta itsenäisenä SEO-konsulttina. Hoidan suunnittelun, toteutuksen ja koodin implementoinnin tai työskentelen yhdessä verkkotoimistosi kanssa.</p>
<p>Aiemmin työskentelin eri SEO-toimistoissa yritysasiakkaiden ja verkkokauppojen hakukoneoptimoinnin parissa päivittäin.</p>
<p>Vuonna 2018 siirryin työskentelemään freelance SEO-konsulttina. Googlen hakukoneoptimoinnin lisäksi rakennan myös ammattimaisia seurantajärjestelmiä, kuten Google Analytics ja Google Tag Manager.</p>
<p>
    Voit lukea arvostelujani <a target="_blank" noopener="true" href="https://www.trustpilot.com/review/bluerivermountains.com">tästä</a> tai tutustua uratarinaani{" "}
    <a target="_blank" noopener="true" href="https://www.linkedin.com/in/matthiaskupperschmidt">
        LinkedInissä.
    </a>{" "}
    Henkilökohtainen tarinani ja muita tietoja löytyvät <Link to="/fi/tietoa">tietoja minusta -sivulta</Link>.
</p>
<br />
<br />
<H as="h6" style={{ "textAlign": "center" }}>Keiden kanssa olen työskennellyt</H>
<ImgScreenshot
    src="about/clients_700px.png"
    alt="asiakkaat, joiden kanssa olen työskennellyt"
    className="article-img"
/>
<br />
<br />
<br />

<H as="h2" style={{ "textAlign": "center" }}>Pyydä tarjous</H>
<ContactForm showHeadline={false} formName="ga consultant (FI) - yhteydenottolomake" />

<H as="h2">SEO-palvelut</H>
<p>
    Yleisesti ottaen työskentelen kaikkien tärkeiden SEO-sijoitustekijöiden parissa optimoidakseni verkkosivustoja hakukoneille, aivan kuten mikä tahansa SEO-yritys.
</p>
<p>Tässä lyhyt lista SEO-palveluistani:</p>
<ul>
    <li>On-page & Off-page optimointi</li>
    <li>Skaalautuvat backlink-rakennusstrategiat</li>
    <li>Sisällön luominen useilla kielillä</li>
    <li>JavaScript SEO</li>
    <li>Latenssin optimointi</li>
    <li>Tekninen SEO-konsultointi</li>
    <li>Sivuston rakenne</li>
    <li>Accelerated Mobile Pages (AMP)</li>
    <li>ei paikallista SEO:ta</li>
</ul>
<p>Vaikka keskityn SEO:hon, tiedän, että hakuliikenteen lisäksi on olemassa muita tehokkaita maksullisia liikenteen lähteitä, kuten Facebook, Twitter tai Google-mainokset. Siksi toteutan SEO-tekniikkani huomioiden muut internet-markkinoinnin alat, jotta voimme <b>hyödyntää orgaanista liikennettä uudelleenmarkkinointiin</b> maksetuissa hakukampanjoissa ja sosiaalisen median kampanjoissa.</p>
<p>Web-kehitysosaamiseni ansiosta voin myös auttaa sinua web-suunnittelutehtävissä HTML:n, CSS:n ja JavaScriptin parissa tai Google Tag Managerin ja Google Analyticsin konfiguroinneissa.</p>

<H as="h2">Tuntiveloitus ja kustannukset</H>
<p>Minun tuntiveloitukseni freelance SEO-konsultointiin on <b>{hourlyRate} €</b> netto per tunti. Kokonaiskustannukset SEO-projektille riippuvat tavoitteistasi, mutta myös nykyisestä sivuston optimoinnista ja kilpailijoidesi panostuksista.</p>
<p>Alla on joitain tekijöitä, jotka vaikuttavat SEO-konsultointityöni hintaan:</p>
<ul>
    <li>Sivuston kokonaismäärä</li>
    <li>Domainin ikä ja auktoriteetti</li>
    <li>Nichen kilpailukyky</li>
    <li>Tekniset haasteet</li>
    <li>Frontendin mukautettavuus</li>
    <li>Kielien määrä, joille optimoimme</li>
    <li>Kuinka paljon uutta verkkosivuston sisältöä tarvitsemme?</li>
    <li>Mikä linkkien rakennusstrategia tarvitaan?</li>
    <li>Onko muita kumppaneita, jotka hoitavat sisältö- tai linkkitehtäviä?</li>
</ul>
<br />
<p>Minimituntimäärä on yleensä <b>vähintään 15 työtuntia kuukaudessa</b>.</p>
<p>Potentiaalisten asiakkaiden on luotettava SEO-asiantuntijan arvioon siitä, kuinka vahvaa kilpailu on, mitä sivuston optimointia tarvitaan ja mitä mahdollisuuksia on olemassa.</p>
<p>Lue lisää alta, missä selitän <Link to="/fi/seo-freelancerina">miten palkata oikea SEO freelancer</Link>.</p>
<p>Hinnoittelun määrittämiseksi sopimuksen laajuus määritellään projektin alussa edellä mainittujen tekijöiden perusteella.</p>
<p><b>Jatkuvaan SEO-optimointiin</b> kuukausittainen projektin laajuus perustuu tehtäviin ja välitavoitteisiin. Tuntiveloituksen, suunniteltujen SEO-toimien ja vaaditun työajan perusteella SEO-kustannukset ovat suunniteltavissa ja rajattavissa joka kuukausi.</p>
<p>Sopimuksen jälkeen voin aloittaa työni heti, kun saan pääsyn verkkosivustolle ja siihen liittyviin työkaluihin.</p>
<p>Tarjoan <b>SEO-raportit</b> live-koontinäytön muodossa, joten sinulla on täydellinen läpinäkyvyys avainsanojen sijoituksista ja SEO-palveluni yleisestä menestyksestä. Maksa itse monista SEO-työkaluista ja sisällytän ne hintaan.</p>
<p>SEO-asiakkaat saattavat joskus tarvita vain <b>pienen kertaluonteisen projektin</b>. Esimerkiksi <b>latenssin optimointi</b>, <b>tekninen SEO-auditointi, kilpailija-analyysi</b> tai tärkeiden laskeutumissivujen toteuttaminen <b>AMP-sivuina</b>.</p>
<p>Tällaiset pienemmät projektit määritellään yksilöllisesti ja ovat tietysti riippumattomia edellä mainituista tekijöistä.</p>

<H as="h2">Freelancerin edut vs. toimisto</H>
<p>Yleisesti ottaen freelancerit sopivat asiakkaille, jotka <b>tietävät, mitä haluavat</b>.</p>
<p>Yleensä se on yritys, joka on työskennellyt aiemmin toimiston kanssa ja jolla on osaava henkilö talossa. He tarvitsevat vähemmän ohjausta ja etsivät mieluummin luotettavaa toteutusta. He haluavat työn tehtävän suurella varmuudella ilman liikaa powerpoint-esityksiä ja lisämyyntitaktiikoita.</p>
<p>Freelancerit toimittavat juuri sen. He keskittyvät toteutukseen ja heillä on rajallinen aika viestintään.</p>
<p>Yhteenvetona freelancerin kanssa työskentelyn edut ovat seuraavat:</p>
<ul>
    <li><b>parempi hinta</b></li>
    <li><b>joustavuus</b> koska voit palkata tarpeen mukaan</li>
    <li>enemmän vuosien <b>kokemusta</b></li>
    <li>enemmän <b>erikoistuneita</b> profiileja saatavilla</li>
    <li><b>viestintä</b> suoraan asiantuntijan kanssa</li>
</ul>

<H as="h2">Haitat</H>
<p>Freelancerin kanssa työskentelyn haitat liittyvät yleensä mutta eivät rajoitu skaalautuvuuteen. Loppujen lopuksi työskentelet yhden henkilön kanssa, jolla on vain kaksi kättä. Tiimillä saat enemmän työtä tehtyä lyhyemmässä ajassa.</p>
<p>Muita mahdollisia haittoja ovat:</p>
<ul>
    <li><b>Skaalausrajoitukset:</b> Jos odotat tehtävien määrän kasvavan, yksi henkilö ei välttämättä riitä työhön.</li>
    <li><b>Asiantuntemuksen rajoitukset:</b> Toimistolla on laajempi valikoima ihmisiä käytettävissään neuvojen antamiseen.</li>
    <li><b>Luotettavuus:</b> Sairaus tai odottamattomat elämän häiriöt voidaan paremmin kompensoida tiimillä.</li>
    <li><b>Persoonallisuuden yhteensopimattomuus:</b> Jos et tule toimeen freelancerisi kanssa, saatat joutua siirtymään eteenpäin ja tuhlata aikaa. Toimisto voisi yhdistää sinut toiseen henkilöön.</li>
</ul>

<H as="h2">Miten palkata oikea freelancer?</H>
<p>Kun palkkaat SEO-freelancereita, on tärkeää vahvistaa kunkin profiilin kokemus ja SEO-taidot.</p>
<p>Esimerkiksi haluat varmistaa, että verkkosivustosi teknologia vastaa freelancerin aiempia projekteja. Jos käytät WordPress-verkkosivustoa, useimmat freelancerit ovat mukavia työskennellä sen kanssa. Mutta jos käytät erittäin räätälöityä web-stackia, jossa on esimerkiksi React tai Angular frontendissä ja Sitecore tai Umbraco backendissä, haluat varmistaa, että se ei aiheuta haasteita.</p>
<p>Yksittäiset SEO-asiantuntijat eivät välttämättä ole tottuneet tekemään muutoksia Gitin kautta, mutta verkkosivustosi vaatii sitä. Joten keskustele muutosten toteutusprosessista verkkosivustolla mahdollisten pullonkaulojen löytämiseksi.</p>
<p>Jotkut yritykset rakentavat verkkonäkyvyytensä itse sisäisen kehittäjän avulla, kun taas toiset työskentelevät verkkotoimiston kanssa. Tällä on vaikutusta SEO-konsultointiin, koska muutokset voidaan joko toteuttaa suoraan tai niiden on mentävä ulkoisen toimiston kautta. Tämä yksin vaikuttaa SEO-kampanjan kustannuksiin ja vaadittuun aikaan, koska viestintäkerroksia tarvitaan enemmän, kunnes muutos toteutetaan.</p>
<p>Joten itsenäisten SEO-asiantuntijoiden on oltava mukavia annetun sisäisten ja ulkoisten tiimien kokoonpanon kanssa ja otettava huomioon sen vaikutus koko SEO-strategiaan.</p>
<p>Myös aiemmat työkokemukset ja missä freelancer on työskennellyt aiemmin, vaikuttavat työetiikkaan ja luotettavuuteen. Upworkin SEO-freelancereiden pääsykynnys on melko alhainen, joten he eivät välttämättä tarjoa oikeaa ehdokasta (en kuitenkaan sano, että se ei ole mahdollista). Siksi toivon tarjoavani jonkin verran läpinäkyvyyttä jakamalla{" "}
    <a target="_blank" noopener="true" href="https://www.linkedin.com/in/matthiaskupperschmidt">
        LinkedIn-profiilini
    </a>{" "}
    etukäteen.
</p>
<p>Kun löydät jonkun, yksinkertainen sähköposti tai täytetty yhteydenottolomake pitäisi riittää keskustelun aloittamiseen. Ammattimainen SEO-freelancer ei kuitenkaan ota jokaista pöydälle putoavaa projektia. Ja se on hyvä asia asiakkaille, joten älä ota hylkäystä henkilökohtaisesti.</p>
<p>SEO-strategiat vaihtelevat eri nicheille ja avainsanojen tarkoituksille. Freelancerit haluavat varmistaa, että nichen vaatimukset ja verkkosivuston teknologia vastaavat heidän asiantuntemustaan.</p>
<p>Jos eivät, asiakkaiden tulisi olla skeptisiä. Ellet tarvitse jonkinlaista standardia SEO-tehtävää, haluat löytää SEO-freelancerin, joka on asiantuntija sinun tapauksessasi.</p>
<p>Kun projektin laajuus ja kustannukset on sovittu kirjallisesti, freelancer tarvitsee pääsyn verkkosivustolle. Tämä tarkoittaa pääsyä frontendiin, CMS:ään ja analytiikkaan ja mahdollisesti FTP-palvelimeen. Kun pääsy on myönnetty, SEO-asiantuntija on palkattu ja työaika alkaa virallisesti.</p>

<H as="h2">Miten minusta tuli hakukoneoptimoinnin freelancer?</H>
<p>Rakensin ensimmäisen yksityisen kotisivuni 14-vuotiaana, jotta voisin jakaa elokuvakokoelmani koulukavereiden kanssa. Kylässämme olin myös se lapsi, joka korjasi tietokoneet ja suoritti monia käyttöjärjestelmän uudelleenasennuksia.</p>
<p>
    Kun olin 19, rakensin Kickerkult.de, pöytäjalkapallon verkkokaupan, ja aloin tehdä hakukoneoptimointia omille avainsanatutkimuksilleni. Silloin meta-avainsanojen ja otsikkotunnisteiden optimointi oli melkein riittävää.
</p>
<p>Verkkosivustojen ja teknologian kanssa puuhastelu oli minulle aina hauskaa, joten oli luonnollista valita kokopäivätyö verkkotoimistoissa ja online-markkinoinnissa myöhemmin. Kollegat ja projektit näissä toimistoissa auttoivat laajentamaan taitojani ja tekemään työprosesseistani ammattimaisempia.</p>
<p>Kun olin saanut tarpeeksi SEO-töitä, eteneminen digitaalisen markkinointitoimiston kanssa kävi yhä vaikeammaksi, joten freelance SEO-konsultointi oli looginen seuraava uravaihtoehto minulle.</p>
<p>Suurin osa <a href="https://www.statista.com/statistics/946967/freelancers-in-europe-by-sector/" target="_blank">freelancereista Euroopassa (39%) työskentelee markkinoinnin ja viestinnän alalla</a>. Halusin syventyä aiheeseeni ja hallita, mitä projekteja teen, joten freelancingista tuli seuraava askel varmistaakseni, että jokainen tunti käytetään merkitykselliseen tehtävään.</p>
<p>Kävi ilmi, etten ole täysin yksin tämän ajattelutavan kanssa. Itse asiassa <a href="https://apo.org.au/sites/default/files/resource-files/2016-05/apo-nid201721.pdf" target="_blank">freelancerit tai iPros ovat nopeimmin kasvava ryhmä EU:n työmarkkinoilla vuodesta 2004 lähtien</a> ja <a href="https://news.malt.com/wp-content/uploads/2019/02/FREELANCING-IN-EUROPE-2-1.pdf" target="_blank">he muodostavat noin 7% EU:n kokonaisvoimasta</a>.</p>
<p>Voin nyt käyttää enemmän aikaa sisällön, avainsanatutkimuksen ja linkkien rakentamiseen, koska minulla kuluu vähemmän aikaa hallinnollisiin tehtäviin tai projektinhallintaan, kun taas asiakkaani saavat enemmän todellista työtä budjetillaan. Kokopäiväinen freelancing antaa minulle nopeamman palautesilmukan, mikä on tarpeen kilpailussa ja yleensä mahdotonta saavuttaa SEO-toimistossa.</p>
<p>Olen työskennellyt freelance SEO-asiantuntijana vuodesta 2018. Työskentelen pääasiassa yritysasiakkaiden kanssa, joilla on monikansallisia verkkosivustoja, sekä sisällönmarkkinointiin perustuvien verkkokauppojen kanssa. En tee paikallista SEO:ta.</p>

<H as="h3">Etsitkö SEO-apua?</H>
<p>
    Jos etsit freelance SEO-asiantuntijaa auttamaan sinua Google-sijoitustesi kanssa, ota rohkeasti yhteyttä.
    Vastaan yleensä tilanteen arvioinnilla ja suunnitelmalla, joka sisältää tarjouksen.
</p>
<p>
    Löydät lisää tietoa <Link to="/fi/tietoa">tietoja minusta -sivulta</Link> ja mahdollisesti mielenkiintoista luettavaa <Link to="/fi/blogi">blogistani</Link>.
</p>
<H as="h4" style={{ "textAlign": "center" }}>Ota yhteyttä saadaksesi sitomattoman tarjouksen.</H>
{/* taking the schema off due to bad implications potentially */}
{/* <Helmet>
<script type="application/ld+json">{Schema}</script>
</Helmet> */}
</MainContent>
</React.Fragment>

</Layout>
);
};

export default SeoFreelancer;

